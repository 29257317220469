<template>
  <v-container fluid>
    <PageNavigation :items="items" />

    <v-row>
      <v-col cols="12">
        <base-material-card title="Price Control" icon="mdi-currency-cny">
          <v-row justify-md="center">
            <v-col class="py-0" cols="12" lg="6" md="6" sm="12">
              <v-card color="#f0f9eb">
                <v-card-title
                  class="headline text-center"
                  style="color: #7dc84b"
                >
                  Preset price panel. When Price not in preset range, must get
                  permit from controller
                </v-card-title>
              </v-card>
            </v-col>
          </v-row>
          <!-- Order Quantity -->
          <v-row justify-md="center">
            <v-col class="py-0" cols="12" lg="3" md="3" sm="6">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.orderQty')"
                suffix="%"
                v-model.number="formData.per_num_percent_min"
              ></v-text-field>
            </v-col>
            <v-col class="py-0" cols="12" lg="3" md="3" sm="6">
              <v-text-field
                suffix="%"
                v-model.number="formData.per_num_percent_max"
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- Order Price -->
          <v-row justify-md="center">
            <v-col class="py-0" cols="12" lg="3" md="3" sm="6">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.orderPrice')"
                suffix="%"
                v-model.number="formData.per_price_percent_min"
              ></v-text-field>
            </v-col>
            <v-col class="py-0" cols="12" lg="3" md="3" sm="6">
              <v-text-field
                suffix="%"
                v-model.number="formData.per_price_percent_max"
              ></v-text-field>
            </v-col>
          </v-row>

          <!-- Setup Fee -->
          <v-row justify-md="center">
            <v-col class="py-0" cols="12" lg="3" md="3" sm="6">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.SetupFee')"
                suffix="%"
                v-model.number="formData.mould_percent_min"
              ></v-text-field>
            </v-col>
            <v-col class="py-0" cols="12" lg="3" md="3" sm="6">
              <v-text-field
                suffix="%"
                v-model.number="formData.mould_percent_max"
              ></v-text-field>
            </v-col>
          </v-row>

          <!-- Shipping Fee -->
          <v-row justify-md="center">
            <v-col class="py-0" cols="12" lg="3" md="3" sm="6">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.ShippingFee')"
                suffix="%"
                v-model.number="formData.post_percent_min"
              ></v-text-field>
            </v-col>
            <v-col class="py-0" cols="12" lg="3" md="3" sm="6">
              <v-text-field
                suffix="%"
                v-model.number="formData.post_percent_max"
              ></v-text-field>
            </v-col>
          </v-row>

          <!-- exchangeRate -->
          <v-row justify-md="center">
            <v-col class="py-0" cols="12" lg="3" md="3" sm="6">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.exchangeRate')"
                suffix="%"
                v-model.number="formData.rate_percent_min"
              ></v-text-field>
            </v-col>
            <v-col class="py-0" cols="12" lg="3" md="3" sm="6">
              <v-text-field
                suffix="%"
                v-model.number="formData.rate_percent_max"
              ></v-text-field>
            </v-col>
          </v-row>

          <!-- totalPrice1 -->
          <v-row justify-md="center">
            <v-col class="py-0" cols="12" lg="3" md="3" sm="6">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.totalPrice1')"
                suffix="%"
                v-model.number="formData.total_price_percent_min"
              ></v-text-field>
            </v-col>
            <v-col class="py-0" cols="12" lg="3" md="3" sm="6">
              <v-text-field
                suffix="%"
                v-model.number="formData.total_price_percent_max"
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- Price Change -->
          <v-row justify-md="center">
            <v-col class="py-0" cols="12" lg="6" md="6" sm="12">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.PriceChange')"
                suffix="RMB"
                v-model.number="formData.per_num_money_max"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify-md="center" class="ml-7">
            <v-col class="py-0 ml-5" cols="12" lg="6" md="6" sm="12">
              <v-btn color="#45af48" class="float-right" @click="submit">{{
                $vuetify.lang.t("$vuetify.sub")
              }}</v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col></v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { infoPriceControl, upsertPriceControl } from "@/api/system";
export default {
  name: "priceControl",
  components: {
    PageNavigation: () => import("@/components/pageNavigation"),
  },
  data() {
    return {
      items: [
        { text: this.$vuetify.lang.t("$vuetify.home") },
        { text: this.$vuetify.lang.t("$vuetify.sysMgr") },
        { text: this.$vuetify.lang.t("$vuetify.priceControl") },
      ],
      datas: [],
      formData: {
        per_num_percent_max: "",
        per_num_percent_min: "",
        per_num_money_max: "",
        per_price_percent_max: "",
        per_price_percent_min: "",
        mould_percent_max: "",
        mould_percent_min: "",
        post_percent_max: "",
        post_percent_min: "",
        rate_percent_max: "",
        rate_percent_min: "",
        total_price_percent_max: "",
        total_price_percent_min: "",
      },
      wait: false,
    };
  },
  methods: {
    submit() {
      this.wait = true;
    },
    getData() {
      infoPriceControl()
        .then((data) => {
          for (var opt in data.item) {
            if (opt !== "per_num_money_max") {
              data.item[opt] = data.item[opt] * 100;
            }
          }
          this.formData = data.item;
        })
        .catch((err) => console.log(err));
    },
  },
  mounted() {
    this.getData();
  },
  watch: {
    wait: function () {
      const data = {};
      for (var opt in this.formData) {
        if (opt !== "per_num_money_max") {
          data[opt] = this.formData[opt] / 100;
        } else {
          data[opt] = this.formData[opt];
        }
      }
      upsertPriceControl(data)
        .then(() => {
          this.getData();
          this.wait = false;
        })
        .catch((err) => console.log("Price Control: ", err));
    },
  },
};
</script>

<style>
</style>